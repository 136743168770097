import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    UncontrolledTooltip
} from 'reactstrap';
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";

import getTransactionsQuery from "../queries/getTransactions";
import getNfcOrderList from "../queries/getNfcOrderList";
import garageService from "../services/garageService";
import dateFormatter from "../utils/datetimeFormatter";

import moment from "moment";
import 'moment/locale/de';

import {Link} from "react-router-dom";
import CustomSearchField from "./CustomSearchField";
import CustomDropdownFilter from "./CustomDropdownFilter";
import CustomDateFilter from "./CustomDateFilter";



export default class TransactionsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.user_id,
            garage_id: this.props.garage_id,
            transactions: [],

            loading: true,
            page: 1,
            totalSize: 0,

            typeFilter: "",
            garageName: "",
            dateFrom: null,
            dateTo: null
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.onPageChange = this.onPageChange.bind(this);
        this.fetchTransactions = this.fetchTransactions.bind(this);

        this.getSearchField = this.getSearchField.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.getTypeFilter = this.getTypeFilter.bind(this);
        this.handleTypeFilter = this.handleTypeFilter.bind(this);

        this.getDateFilter = this.getDateFilter.bind(this);
        this.handleDateFilter = this.handleDateFilter.bind(this);
    }

    transactionType = {
        'PARKING': 'Parkvorgang',
        'RESERVATION': 'Reservierung',
        'ACCESS': 'Zugang',
        'VISIT': 'Besuch',
        'NFC': "NFC Tag",
    };

    static IDTooltipFormatter(cell, row) {
        const id = row.id;

        return (
            <div id={'Tooltip-' + id}>
                {cell}
                <UncontrolledTooltip placement="top" target={'Tooltip-' + id}>
                    {cell}
                </UncontrolledTooltip>
            </div>
        )
    }
    static dateFormatter(cell, row) {
        if (!cell && row.type === 'NFC') {
            return ""
        } 

        if (!cell) {
            return "laufend"
        } 

        return moment(cell).format("DD.MM.YYYY HH:mm");
    }

    static enumFormatter = (cell, row, enumObject) => {
        return enumObject[cell];
    };

    static durationFormatter(cell, row) {
        if (row.type === 'NFC') {
            return ''
        };

        let diff = (row.end_time ? new Date(row.end_time) : new Date()) - new Date(row.start_time);
        return moment.duration(diff).locale('de').humanize();
    }

    static garageLinker(cell) {
        if(cell && cell.id && cell.name) {
            const id = cell.id;
            return <Link to={`/garages/${id}`}>{cell.name}</Link>;
        }
        return "-"
    }

    static userLinker(cell, row) {
        const id = row.user;
        return <Link to={`/users/${id}`}>{row.user_email}</Link>;
    }

    componentDidMount() {
        this.fetchTransactions()
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            transactions: [],
            page
        }, this.fetchTransactions)
    }

    getQueryParams() {
        let params = {
            limit: 10,
            offset: (this.state.page - 1) * 10,
            garageName: this.state.garageName,
            type: this.state.typeFilter,
            dateFrom: this.state.dateFrom !== null ? this.state.dateFrom + "Z" : null,
            dateTo: this.state.dateTo !== null ? moment(this.state.dateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + "Z" : null
        };

        if(this.props.by === "user") {
            params["user_id"] = this.state.user_id;
        } else {
            params["garage_id"] = this.state.garage_id
        }

        return params;
    }

    async fetchTransactions() {
        try {
            let transactions = [];
            let count = 0; 
            if (this.state.typeFilter !== 'NFC') {
                const transactionRes = await garageService.getService(getTransactionsQuery(this.getQueryParams()))
                transactions = transactionRes.data.getTransactions.transactions;
                count += transactionRes.data.getTransactions.count;
            }

            if ((this.props.by === "user" && this.state.typeFilter === 'NFC') || (this.props.by === "user" && this.state.typeFilter === '')) {
                const nfcRes = await garageService.getService(getNfcOrderList({ user_id: this.state.user_id }));
                const nfcOrders = nfcRes.data.NFCOrderList.NFCOrders;

                count += nfcOrders.length;

                if (nfcOrders) {
                    transactions = transactions.concat(nfcOrders.map((nfc) => {
                        nfc.start_time = new Date(nfc.created_at);
                        nfc.total_price = nfc.price;
                        nfc.type = "NFC"

                        delete nfc.created_at;
                        delete nfc.price;
                        
                        return nfc;
                    })).sort((a, b) => new Date(a.start_time) - new Date(b.start_time)).reverse();
                }
            }

            this.setState({
                transactions: transactions,
                loading: false,
                totalSize: count,
            })

        } catch (err) {
            this.setState({
                transactions: [],
                loading: false,
                totalSize: 0,
            })
            console.log(JSON.stringify(err));
            let errors = err.networkError.result.errors;
            this.unsuccessful(errors.map((error) => error.message).join(" "));
        }
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.searchQuery}
                placeholder="Search by garage name"
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');

        if(this.state.searchQuery !== val) {
            this.setState({
                page: 1,
                loading: true,
                transactions: [],
                garageName: val
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchTransactions, this.typingInterval)
            })
        }
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {this.onClearButtonClicked(onClick)}}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.garageName !== "") {
            this.setState({
                page: 1,
                loading: true,
                transactions: [],
                garageName: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchTransactions, this.typingInterval)
            })
        }
    }

    getTypeFilter() {
        let options = [
            {value: "PARKING", label: "Parkvorgang"},
            {value: "RESERVATION", label: "Reservierung"},
            {value: "ACCESS", label: "Zugang"},
            {value: "VISIT", label:"Besuch"},
            {value: "NFC", label:"NFC Tag"},
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    handleTypeFilter(val) {
        this.setState({
            loading: true,
            transactions: [],
            page: 1,
            typeFilter: val
        }, this.fetchTransactions)
    }

    getDateFilter(field) {
        return (
            <CustomDateFilter
                field={field}
                filterHandler={this.handleDateFilter}
            />
        )
    }

    handleDateFilter(field, val) {
        this.setState({
            loading: true,
            transactions: [],
            page: 1,
            [field]: val ? moment(val, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
        }, this.fetchTransactions)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            clearSearchBtn: this.getClearButton,
            onPageChange: this.onPageChange,

            page: this.state.page,
            sizePerPage: 10
        }
    }

    getNoDataTableContent() {
        if(this.state.loading){
            return (<div className="sk-rotating-plane"/>)
        }
        return "Keine Transaktionen gefunden"
    }


    render() {
        return (
            <Card>
                <CardHeader>
                    Transaktionen
                </CardHeader>
                <CardBody>
                    <BootstrapTable data={this.state.transactions} version="4" striped remote fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true} options={this.tableOptions} search={this.props.by === "user"}>
                        <TableHeaderColumn width='120' isKey dataField="id" dataFormat={TransactionsTable.IDTooltipFormatter}>ID</TableHeaderColumn>
                        {this.props.by === "user" ? (
                            <TableHeaderColumn width='150' dataFormat={TransactionsTable.garageLinker} dataField="transactionGarage">Garage</TableHeaderColumn>

                        ) : (
                            <TableHeaderColumn width='150' dataFormat={TransactionsTable.userLinker} dataField="user_email">Benutzer</TableHeaderColumn>

                            ) }

                        <TableHeaderColumn dataField="type"
                                           width='120'
                                           filterFormatted
                                           dataFormat={ TransactionsTable.enumFormatter }
                                           formatExtraData={ this.transactionType }
                                           filter={{ type: 'CustomFilter', getElement: this.getTypeFilter }}>Transaktionstyp</TableHeaderColumn>
                        <TableHeaderColumn width='140' dataField="start_time" dataFormat={ TransactionsTable.dateFormatter } filter={ { type: 'CustomFilter', getElement: () => this.getDateFilter("dateFrom") } }>Transaktionsstart</TableHeaderColumn>
                        <TableHeaderColumn width='140' dataField="end_time" dataFormat={ TransactionsTable.dateFormatter } filter={ { type: 'CustomFilter', getElement: () => this.getDateFilter("dateTo") } }>Transaktionsende</TableHeaderColumn>
                        <TableHeaderColumn width='120' dataField="start_time" dataFormat={ TransactionsTable.durationFormatter } >Dauer</TableHeaderColumn>
                        <TableHeaderColumn width='80' dataField="total_price" >Preis</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }

}
