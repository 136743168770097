import React, {Component} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import 'react-select/dist/react-select.min.css';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Widget from "../../components/Widget/Widget";
import gatewayService from "../../services/gatewayService";
import garageService from "../../services/garageService";
import updateGateway from "../../mutations/updateGateway";
import getGatewayById from "../../queries/getGatewayById";
import getTenantsQuery from "../../queries/getTenantsQuery";
import assignGateway from "../../mutations/assignGateway";
import returnToPool from "../../mutations/returnToPool";

import getGaragesQuery from '../../queries/getGarages';
import setNewNfcGateway from "../../mutations/setNewNfcGateway";
import datetimeFormatter from "../../utils/datetimeFormatter";
import Logs from "../../components/Logs";
import {history} from "../../App";
import convertGateway from "../../mutations/convertGateway";
import {Redirect} from "react-router-dom";
import Select from "react-select";


class Gateway extends Component {

    constructor(props) {
        super(props);

        this.state = {
            typeWarning: false,
            loading: true,
            disabled: true,
            unassign: false,
            assign: false,
            convert: false,
            reasonUnassign: '',
            reasonAssign: '',
            garage: '',
            value: '',
            assignedGarage: '',
            convertToGwid: '',
            converted: false,
            productTypes: [
                {value: "B2C", label: "B2C"},
                {value: "B2B", label: "B2B"},
                {value: "B2B_B2C", label: "Hybrid"},
            ],
        };

        this.typingTimer = null;
        this.typingInterval = 600;

        this.handleChange = this.handleChange.bind(this);
        this.toggleUnassign = this.toggleUnassign.bind(this);
        this.toggleAssign = this.toggleAssign.bind(this);
        this.toggleTypeWarning = this.toggleTypeWarning.bind(this);
        this.toggleDisabled = this.toggleDisabled.bind(this);
        this.onGarageChanged = this.onGarageChanged.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.updateGateway = this.updateGateway.bind(this);
        this.success = this.success.bind(this);
        this.assignGateway = this.assignGateway.bind(this);
        this.returnToPool = this.returnToPool.bind(this);
        this.setAsNFCGateway = this.setAsNFCGateway.bind(this);
        this.renderInitialHeader = this.renderInitialHeader.bind(this);
        this.renderAssignedHeader = this.renderAssignedHeader.bind(this);
        this.renderNFCHeader = this.renderNFCHeader.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.loadGarages = this.loadGarages.bind(this);
        this.toggleConvert = this.toggleConvert.bind(this);
        this.onConvertGatewayGwidChange = this.onConvertGatewayGwidChange.bind(this);
        this.convertGateway = this.convertGateway.bind(this);
        this.handleProductType = this.handleProductType.bind(this)
        this.getTenants = this.getTenants.bind(this)
        this.handleTenant = this.handleTenant.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let gateway = this.state.gateway;

        if (name === "type" && !value.length) {
            value = null;
        }

        if (value === "NFC_WRITE") {
            return this.toggleTypeWarning();
        }

        gateway[name] = value;

        this.setState({
            gateway: gateway,
            [name]: value
        });
    }

    handleProductType(product_type) {
        this.setState({
            gateway: {
                ...this.state.gateway,
                product_type: !product_type ? null : product_type.value
            }
        });
    }

    handleTenant(tenant) {
        this.setState({
            gateway: {
                ...this.state.gateway,
                tenant_id: !tenant ? null : tenant.value,
                tenant_name: !tenant ? null : tenant.label
            }
        })
    }

    getTenants() {
        gatewayService.getService(getTenantsQuery())
            .then((res) => {
                let tenants = res.data.getTenants.tenants;
                tenants = tenants.map((item) => {
                    return {
                        value: item.Id,
                        label: item.Name
                    }
                })
                this.setState({
                    tenants: tenants
                })
            })
            .catch((err) => {
                console.log(err)
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            })
    }

    unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    onGarageChanged(assignedGarage) {
        this.setState({
            assignedGarage: assignedGarage
        });
    }

    onConvertGatewayGwidChange(event) {

        this.setState({
            convertToGwid: event.target.value
        });
    }

    loadGarages(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    offset: 0,
                    limit: 10,
                    searchTerm: value
                };

                garageService.getService(getGaragesQuery(params))
                    .then((garages) => {
                        resolve({
                            options: garages.data.getGarages.garages.map((garage) => {
                                return {value: garage.id, label: garage.name}
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }, this.typingInterval)

        })
    }

    toggleUnassign() {
        this.setState({
            unassign: !this.state.unassign,
        });
    }

    toggleAssign() {
        this.setState({
            assign: !this.state.assign,
        });
    }

    toggleTypeWarning() {
        this.setState({
            typeWarning: !this.state.typeWarning,
        });
    }

    toggleConvert() {
        this.setState({
            convert: !this.state.convert,
        });
    }

    toggleDisabled() {
        this.setState({
            disabled: !this.state.disabled,
        });
    }

    updateGateway() {
        garageService.createService(updateGateway(this.state.gateway))
            .then((response) => {
                this.setState({
                    gateway: {
                        ...response.data.updateGateway.gateway,
                        gatewayGarage: this.state.gateway.gatewayGarage,
                    },
                    gatewayName: response.data.updateGateway.gateway.name,
                }, () => {
                    this.success();
                    this.toggleDisabled();
                    document.title = "Gateway | " + this.state.gatewayName;
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    assignGateway() {

        let data = {
            gatewayID: this.state.gateway.id,
            garageTarget: this.state.assignedGarage.value,
            customer_id: this.state.gateway.customer_id,
        };

        garageService.createService(assignGateway(data))
            .then((response) => {
                this.setState({
                    gateway: {
                        ...this.state.gateway,
                        gatewayGarage: response.data.assignGateway.gateway.gatewayGarage,
                    },
                    assign: !this.state.assign,
                }, () => {
                    this.success();
                })
            })
            .catch((err) => {
                console.log(err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    convertGateway() {
        let data = {
            id: this.state.gateway.id,
            gwid: `PAY_${this.state.convertToGwid}`,
        };

        garageService.createService(convertGateway(data))
            .then((response) => {
                this.setState({
                    convert: !this.state.convert,
                }, () => {
                    this.success();
                    setTimeout(() => this.setState({
                        converted: true
                    }), 2000);
                });
            })
            .catch((err) => {
                console.log(err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }


    returnToPool() {

        let data = {
            gatewayID: this.state.gateway.id,
            garageFrom: this.state.gateway.gatewayGarage.id,
        };

        garageService.createService(returnToPool(data))
            .then((response) => {
                this.setState({
                    gateway: {
                        ...this.state.gateway,
                        type: '',
                        gatewayGarage: null,
                    },
                    unassign: !this.state.unassign,
                }, () => {
                    this.success();
                })
            })
            .catch((err) => {
                console.log(err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    setAsNFCGateway() {
        this.setState({
            nfcLoading: true,
        });
        garageService.createService(setNewNfcGateway(this.state.gateway.id))
            .then((response) => {
                this.setState({
                    gateway: {
                        ...this.state.gateway,
                        type: response.data.setNewNfcGateway.gateway.type
                    },
                    nfcLoading: false,
                }, () => {
                    this.success();
                })
            })
            .catch((err) => {
                this.setState({
                    nfcLoading: false,
                });
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    componentDidMount() {

        const {match: {params}} = this.props;

        gatewayService.getService(getGatewayById(params))
            .then((gateway) => {
                this.setState({
                    gateway: gateway.data.getGatewayById.gateway,
                    gatewayName: gateway.data.getGatewayById.gateway.name,
                    loading: false,
                }, () => {
                    document.title = "Gateway | " + this.state.gatewayName;
                    this.getTenants()
                })
            })
            .catch((err) => {
                if (err.networkError.statusCode === 404) {
                    history.push('/404')
                }
            });
    }

    renderInitialHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="6" lg="6">
                    <Widget icon="icon-organization" color="info"
                            header={this.state.gatewayName + " | " + this.state.gateway.id}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-action-redo"
                        color="success"
                        header="Konvertieren"
                        invert
                        style={{cursor: "pointer"}}
                        onClick={this.toggleConvert}
                    />
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-plus"
                        color="success"
                        header="Gateway zuweisen"
                        invert
                        style={{cursor: "pointer"}}
                        onClick={this.toggleAssign}
                    />
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    {
                        this.state.nfcLoading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="NFC Gateway"
                                invert
                                style={{cursor: "pointer"}}
                                onClick={this.setAsNFCGateway}
                            />
                    }
                </Col>
            </Row>
        )
    }

    renderAssignedHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="10" lg="10">
                    <Widget icon="icon-organization" color="info"
                            header={this.state.gatewayName + " | " + this.state.gateway.id}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-minus"
                        color="warning"
                        header="Gateway aufheben"
                        invert
                        style={{cursor: "pointer"}}
                        onClick={this.toggleUnassign}
                    />
                </Col>
            </Row>
        )
    }

    renderNFCHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                    <Widget icon="icon-organization" color="info"
                            header={this.state.gatewayName + " | " + this.state.gateway.id}/>
                </Col>
            </Row>
        )
    }

    renderHeader() {
        if (this.state.gateway.type === 'NFC_WRITE') {
            return this.renderNFCHeader()
        } else if (this.state.gateway.type !== 'NFC_WRITE' && !this.state.gateway.gatewayGarage) {
            return this.renderInitialHeader()
        } else if (this.state.gateway.gatewayGarage) {
            return this.renderAssignedHeader()
        }
    }

    render() {

        const containerStyle = {
            zIndex: 1999
        };

        if (this.state.loading) {
            return (
                <div>
                    <div className="sk-rotating-plane"/>
                    <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                </div>
            );
        }

        if (this.state.converted) {
            return <Redirect to={`/gateways2/PAY_${this.state.convertToGwid}`}/>
        }

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                {this.renderHeader()}
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                Gateway Infos
                                <div className="card-header-actions">
                                    {
                                        !this.state.disabled
                                            ?
                                            <div><Button type="submit" size="sm" color="success" className={"mr-1"}
                                                         onClick={this.updateGateway}><i
                                                className="fa fa-dot-circle-o"></i> Speichern</Button> <Button
                                                type="reset" size="sm" color="danger" onClick={this.toggleDisabled}><i
                                                className="fa fa-ban"></i> Abbrechen</Button></div>
                                            :
                                            <a className="card-header-action btn btn-setting"
                                               onClick={this.toggleDisabled}><i className="icon-pencil"></i></a>
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12" md="6">
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">ID</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="id" id="id" value={this.state.gateway.id}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Name</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="name" id="name"
                                                       value={!this.state.gateway.name ? "" : this.state.gateway.name}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Microtronics ID</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="microtronics_id" id="microtronics_id"
                                                       value={this.state.gateway.microtronics_id}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Device ID</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="device_id" id="device_id"
                                                       value={this.state.gateway.device_id} onChange={this.handleChange}
                                                       disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Typ</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="select" name="type" id="type"
                                                       value={!this.state.gateway.type ? "k.A" : this.state.gateway.type}
                                                       onChange={this.handleChange}
                                                       disabled={this.state.gateway.type === 'NFC_WRITE' || this.state.disabled}>
                                                    <option value="">-</option>
                                                    <option value="CHECK_IN">Check In</option>
                                                    <option value="CHECK_OUT">Check Out</option>
                                                    <option value="CHECK_IN_OUT">Check In/Out</option>
                                                    <option value="MIDGATEWAY">Zwischentür</option>
                                                    <option value="MIDGATEWAY_ONLINE">Zwischentür (Online)</option>
                                                    <option value="MIDGATEWAY_OFFLINE">Zwischentür (Offline)</option>
                                                    <option value="NFC_WRITE">NFC Gateway</option>
                                                    <option value="EXIT_BARRIER">Ausfahrtsschranken</option>
                                                    <option value="EXIT_BARRIER_ONLINE">Ausfahrtsschranken (Online)</option>
                                                    <option value="EXIT_BARRIER_OFFLINE">Ausfahrtsschranken (Offline)</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">State</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                {
                                                    this.state.gateway.state === 'ONLINE' ? <div><i
                                                            className="fa fa-circle text-success Blink"/> {this.state.gateway.state}
                                                        </div> :
                                                        <div><i
                                                            className="fa fa-circle text-danger Blink"/> {this.state.gateway.state}
                                                        </div>
                                                }
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Aktiv</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="select" id="active" name="active"
                                                       value={!this.state.gateway.active ? false : true}
                                                       onChange={this.handleChange} disabled={this.state.disabled}>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Garage</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="name" id="name"
                                                       value={!this.state.gateway.gatewayGarage ? "-" : this.state.gateway.gatewayGarage.name}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Tenant</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Select
                                                    value={!this.state.gateway.tenant_id ? null : this.state.gateway.tenant_id}
                                                    options={this.state.tenants}
                                                    onChange={this.handleTenant}
                                                    disabled={this.state.disabled}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="12" md="6">
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Signalstärke</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="signal_strength" id="signal_strength"
                                                       value={this.state.gateway.connection_device.signal_strength}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Location</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="location" id="location"
                                                       value={!this.state.gateway.location ? "" : this.state.gateway.location}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="led1_hold">LED hold</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="led1_hold" id="led1_hold"
                                                       value={!this.state.gateway.led1_hold ? "" : this.state.gateway.led1_hold}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Relay hold</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="relay_hold" id="relay_hold"
                                                       value={!this.state.gateway.relay_hold ? "" : this.state.gateway.relay_hold}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="gwid">GWID</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="gwid" id="gwid"
                                                       value={!this.state.gateway.gwid ? "" : this.state.gateway.gwid}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Erstellt am</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="created_at" id="created_at"
                                                       value={datetimeFormatter.forDisplay(this.state.gateway.created_at)}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Aktualisiert am</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="updated_at" id="updated_at"
                                                       value={datetimeFormatter.forDisplay(this.state.gateway.updated_at)}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Gelöscht am</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Input type="text" name="deleted_at" id="deleted_at"
                                                       value={!this.state.gateway.deleted_at ? "-" : datetimeFormatter.forDisplay(this.state.gateway.deleted_at)}
                                                       onChange={this.handleChange} disabled/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label htmlFor="selectSm">Product type</Label>
                                            </Col>
                                            <Col xs="12" md="9">
                                                <Select
                                                    value={!this.state.gateway.product_type ? null : this.state.gateway.product_type}
                                                    options={this.state.productTypes}
                                                    onChange={this.handleProductType}
                                                    disabled={this.state.disabled}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12">
                                        <FormGroup row>
                                            <Col md="3">
                                                <Label>Notiz</Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col xs="12" md="12">
                                                <Input type="textarea" name="note" id="note"
                                                       value={!this.state.gateway.note ? "" : this.state.gateway.note}
                                                       onChange={this.handleChange} disabled={this.state.disabled}/>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        {
                            this.state.garageDataLoading ?
                                <div className="sk-rotating-plane"/>
                                :
                                <Logs
                                    object_id={this.state.gateway.id}
                                    object_content_type="GATEWAY"
                                />
                        }
                    </Col>
                </Row>

                {/* Modals */}

                {/* Unassign gateway */}
                <Modal isOpen={this.state.unassign} toggle={this.toggleUnassign}
                       className={'modal-warning ' + this.props.className}>
                    <ModalHeader toggle={this.toggleUnassign}>Gateway aufheben</ModalHeader>
                    <ModalFooter>
                        <Button color="warning" onClick={this.returnToPool}>Gateway aufheben</Button>{' '}
                        <Button color="secondary" onClick={this.toggleUnassign}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

                {/* NFC_GATEWAY Typechange warning */}
                <Modal isOpen={this.state.typeWarning} toggle={this.toggleTypeWarning}
                       className={'modal-warning ' + this.props.className}>
                    <ModalHeader toggle={this.toggleTypeWarning}>Achtung</ModalHeader>
                    <ModalBody>
                        <Label htmlFor="newGatewayGwid">Bist du sicher, dass du den Gateway-Typ auf 'NFC Gateway' ändern möchtest?</Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={()=>{
                            let gateway = this.state.gateway;
                    
                            gateway['type'] = 'NFC_WRITE';
                    
                            this.setState({
                                gateway: gateway,
                                'type': 'NFC_WRITE'
                            });
                            this.toggleTypeWarning();
                        }}>Yes</Button>
                        <Button color="secondary" onClick={this.toggleTypeWarning}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

                {/* Assign gateway */}
                <Modal isOpen={this.state.assign} toggle={this.toggleAssign}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleAssign}>Gateway zuweisen</ModalHeader>
                    <ModalBody>
                        <AsyncSelect
                            name="assignedGarage"
                            loadOptions={this.loadGarages}
                            value={this.state.assignedGarage}
                            onChange={this.onGarageChanged}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.assignGateway}>Gateway zuweisen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleAssign}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

                {/* Convert gateway */}
                <Modal isOpen={this.state.convert} toggle={this.toggleConvert}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleConvert}>Gateway konvertieren</ModalHeader>
                    <ModalBody>
                        <Label htmlFor="newGatewayGwid">Neue Gateway GWID</Label>

                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>PAY_</InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" value={this.state.convertToGwid}
                                   name="newGatewayGwid" id="newGatewayGwid" placeholder="1234567"
                                   onChange={this.onConvertGatewayGwidChange}/>
                        </InputGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.convertGateway}>Konvertieren</Button>{' '}
                        <Button color="secondary" onClick={this.toggleConvert}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default Gateway;
