import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-select/dist/react-select.min.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AsyncSelect from 'react-select/lib/Async';

import Widget from "../../components/Widget/Widget";
import garageService from "../../services/garageService";
import companyService from "../../services/companyService";
import contactService from "../../services/contactService";

import createGarageMutation from '../../mutations/createGarage';
import warning from "../../assets/img/warning.png";

class AddGarage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            garage_owner: '',
            street: '',
            street_number: '',
            zip_code: '',
            city: '',
            country: '',
            latitude: '',
            longitude: '',
            echarger: false,
            narrow: null,
            barrierfree: false,
            video_surveilance: false,
            footprint: '',
            exit_type: 'automatisch | automatically',
            garage_height: '',
            indoor: false,
            levels: '',
            type: '',
            tolerance_minutes: '',
            free_minutes: '',
            person_responsible: '',
            property_management_company: '',
            door_maintenance_company: '',
            max_weight: '',
            handicapped_spots: '',
            security_guard: '',
            loading: true,
            parking_spaces: 0,
            tariff: 0.0,
            tariff_daily: 0.0,
            tariff_tact: '',
            hour: 0.0,
            day: 0.0,
            week: 0.0,
            month: 0.0,
            active: true,
            check_out_address_street: '',
            check_out_address_street_number: '',
            check_out_address_zip_code: '',
            check_out_address_city: '',
            entrance_type_foot: 'VIA_STAIRCASE',
            exit_type_foot: 'VIA_STAIRCASE',
            check_out_latitude: '',
            check_out_longitude: '',
            gateway_type: '',
            smart_revenue: null,
            smart_access: null,
        };

        this.garageOwnerTypingTimer = null;
        this.prContactTimer = null;
        this.pmCompanyTimer = null;
        this.dmCompanyTimer = null;

        this.typingInterval = 600;

        this.handleChange = this.handleChange.bind(this);
        this.sanitizeData = this.sanitizeData.bind(this);
        this.unsuccessful = this.unsuccessful.bind(this);
        this.success = this.success.bind(this);
        this.createGarage = this.createGarage.bind(this);
        this.saveDMCompany = this.saveDMCompany.bind(this);
        this.savePMCompany = this.savePMCompany.bind(this);
        this.savePR = this.savePR.bind(this);
        this.saveGOCompany = this.saveGOCompany.bind(this);

        this.loadGarageOwnerOptions = this.loadGarageOwnerOptions.bind(this);
        this.loadPROptions = this.loadPROptions.bind(this);
        this.loadPMOptions = this.loadPMOptions.bind(this);
        this.loadDMOptions = this.loadDMOptions.bind(this);
    }

    saveDMCompany(company) {
        // door maintentance
        this.setState({
            door_maintenance_company: !company ? null : company
        });
    }

    savePMCompany(company) {
        // property management
        this.setState({
            property_management_company: !company ? null : company
        });
    }

    savePR(person_responsible) {
        // person responsible
        this.setState({
            person_responsible: person_responsible.map((person) => person)
        });
    }

    saveGOCompany(company) {
        this.setState({
            garage_owner: !company ? null : company
        });
    }

    sanitizeData(val) {
        return (val && val.length) ? val : ""
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        } else {
            value = value;
        }

        this.setState({
            [name]: value
        });
    }

    createGarage() {
        const {
            garage_owner,
            person_responsible,
            property_management_company,
            door_maintenance_company,
        } = this.state;

        garageService.createService(createGarageMutation({
            ...this.state,
            garage_owner: garage_owner ? garage_owner.value : null,
            person_responsible: person_responsible && person_responsible.length ? person_responsible.map(person => person.value) : null,
            property_management_company: property_management_company ? property_management_company.value : null,
            door_maintenance_company: door_maintenance_company ? door_maintenance_company.value : null,

        }))
            .then((response) => {
                this.success();
                this.props.history.push('/garages/' + response.data.createGarage.garage.id)
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }


    unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    loadGarageOwnerOptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.garageOwnerTypingTimer);

            this.garageOwnerTypingTimer = setTimeout(() => {

                companyService.getCompanies(1, value)
                    .then((companies) => {
                        resolve({
                            options: companies.data.results.map((company) => {
                                return {value: company.id, label: company.name}
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });


            }, this.typingInterval)
        });
    }

    loadPROptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.prContactTimer);

            this.prContactTimer = setTimeout(() => {
                contactService.getContacts(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((contact) => {
                                return {value: contact.id, label: contact.first_name + " " + contact.last_name}
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }

    loadPMOptions(value) {
        return new Promise(resolve => {

            clearTimeout(this.pmCompanyTimer);

            this.pmCompanyTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((company) => {
                                return {value: company.id, label: company.name}
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });

        });
    }

    loadDMOptions(value) {
        return new Promise(resolve => {
            clearTimeout(this.dmCompanyTimer);

            this.dmCompanyTimer = setTimeout(() => {
                companyService.getCompanies(1, value)
                    .then((response) => {
                        resolve({
                            options: response.data.results.map((company) => {
                                return {value: company.id, label: company.name}
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, this.typingInterval)
        })
    }


    render() {
        const containerStyle = {
            zIndex: 1999
        };

        return (
            <div className="animated fadeIn">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" md="10" lg="10">
                        <Widget icon="icon-home" color="info" header="Garage hinzufügen"/>
                    </Col>
                    <Col xs="12" sm="12" md="10" lg="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Speichern"
                            invert
                            style={{cursor: this.state.name.length < 3 ? "not-allowed" : "pointer"}}
                            onClick={this.state.name.length < 3 ? null : this.createGarage}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        <Card>
                            <CardHeader>
                                <strong>Garagendaten</strong>
                            </CardHeader>
                            <CardBody className="pb-0">
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="name">Garage Name *</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="name" id="name" value={this.state.name}
                                                   onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="garage_owner">Garagenbesitzer</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <AsyncSelect
                                                value={!this.state.garage_owner ? null : this.state.garage_owner}
                                                onChange={this.saveGOCompany}
                                                loadOptions={this.loadGarageOwnerOptions}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="street">Straße*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" id="street" name="street" value={this.state.street}
                                                   onChange={this.handleChange}
                                                   required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="street_number">Nr*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" id="street_number" name="street_number"
                                                   value={this.state.street_number} onChange={this.handleChange}
                                                   required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="zip_code">PLZ*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" id="zip_code" name="zip_code" value={this.state.zip_code}
                                                   onChange={this.handleChange}
                                                   required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="plz">Stadt*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" id="city" name="city" value={this.state.city}
                                                   onChange={this.handleChange}
                                                   required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="plz">Land*</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="select" id="country" name="country" value={this.state.country}
                                                   onChange={this.handleChange} required >
                                                <option value="">-</option>
                                                <option value="AT">Österreich</option>
                                                <option value="DE">Deutschland</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                Check-Out Daten
                            </CardHeader>
                            <CardBody className="pb-0">
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="strasse">Check-Out Straße</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_address_street"
                                                           name="check_out_address_street"
                                                           value={this.state.check_out_address_street}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="nr">Check-Out Nr</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_address_street_number"
                                                           name="check_out_address_street_number"
                                                           value={this.state.check_out_address_street_number}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="plz">Check-Out PLZ</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_address_zip_code"
                                                           name="check_out_address_zip_code"
                                                           value={this.state.check_out_address_zip_code}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="plz">Check-Out Stadt</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_address_city"
                                                           name="check_out_address_city"
                                                           value={this.state.check_out_address_city}
                                                           onChange={this.handleChange}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="entrance_type_foot">Eingangstyp</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" id="entrance_type_foot"
                                                           name="entrance_type_foot"
                                                           value={this.state.entrance_type_foot}
                                                           onChange={this.handleChange}
                                                    >
                                                        <option value={"VIA_STAIRCASE"}>Über die Treppe</option>
                                                        <option value={"VIA_GATE"}>Über Tor</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="exit_type_foot">Exit-Typ*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="select" id="exit_type_foot"
                                                           name="exit_type_foot"
                                                           value={this.state.exit_type_foot}
                                                           onChange={this.handleChange}
                                                           required
                                                    >
                                                        <option value={"VIA_STAIRCASE"}>Über die Treppe</option>
                                                        <option value={"VIA_GATE"}>Über Tor</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="latitude">Breitengrad*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_latitude" name="check_out_latitude"
                                                           value={this.state.check_out_latitude}
                                                           onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="3">
                                                    <Label htmlFor="longitude">Längengrad*</Label>
                                                </Col>
                                                <Col xs="12" md="9">
                                                    <Input type="text" id="check_out_longitude"
                                                           name="check_out_longitude"
                                                           value={this.state.check_out_longitude}
                                                           onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="8" lg="8">
                        <Card>
                            <CardHeader>
                                <strong>Technische Daten</strong>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <Row>
                                        <Col xs="12" sm="12" md="6" lg="6">
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="latitude">Breitengrad*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="text" name="latitude" id="latitude"
                                                           value={this.state.latitude} onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="longitude">Längengrad*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="text" id="longitude" name="longitude"
                                                           value={this.state.longitude} onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="echarger">e-Ladegerät</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="echarger" name="echarger"
                                                           value={this.state.echarger} onChange={this.handleChange}>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="narrow"><img width={24} src={warning}/> Schmal</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="narrow" name="narrow"
                                                           value={this.state.narrow} onChange={this.handleChange}
                                                           required>
                                                        <option>Bitte auswahlen</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="accessible">Barrierefrei</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="barrierfree" name="barrierfree"
                                                           value={this.state.barrierfree} onChange={this.handleChange}>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="cctv">CCTV</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="video_surveilance" name="video_surveilance"
                                                           value={this.state.video_surveilance}
                                                           onChange={this.handleChange}>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="spaceWidth">Stellplatzbreite*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="number" id="footprint" name="footprint"
                                                           value={this.state.footprint} onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="exitType">Ausgangstyp</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="exit_type"
                                                           name="exit_type"
                                                           value={this.state.exit_type}
                                                           onChange={this.handleChange}
                                                    >
                                                        <option value={"automatisch | automatically"}>automatisch | automatically</option>
                                                        <option value={"per Seilzug | by pulling the rope"}>per Seilzug | by pulling the rope</option>
                                                        <option value={"via App oder NFC | via App or NFC"}>via App oder NFC | via App or NFC</option>
                                                        <option value={"Türöffner | Door Opener"}>Türöffner | Door Opener</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="selectSm">Gateway Typ</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" name="gateway_type" id="gateway_type"
                                                           value={this.sanitizeData(this.state.gateway_type)}
                                                           onChange={this.handleChange}>
                                                        <option value={""}>-</option>
                                                        <option value={"1.0"}>1.0</option>
                                                        <option value={"2.0"}>2.0</option>
                                                        <option value={"PC"}>Payuca Connect</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="selectSm">Produkttyp Smart Revenue</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" name="smart_revenue" id="smart_revenue"
                                                           value={this.state.smart_revenue}
                                                           onChange={this.handleChange}>
                                                        <option value={null}>-</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="selectSm">Produkttyp Smart Access</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" name="smart_access" id="smart_access"
                                                           value={this.state.smart_access}
                                                           onChange={this.handleChange}>
                                                        <option value={null}>-</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6">
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="garageHeight">Garagenhöhe*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="number" id="garage_height" name="garage_height"
                                                           value={this.state.garage_height}
                                                           onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="indoor">Innen</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="indoor" name="indoor"
                                                           value={this.state.indoor} onChange={this.handleChange}>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="floors">Stockwerke</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="text" id="levels" name="levels"
                                                           value={this.state.levels} onChange={this.handleChange}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="garageType">Garagentyp*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="type" name="type"
                                                           value={this.sanitizeData(this.state.type)}
                                                           onChange={this.handleChange}
                                                           required
                                                    >
                                                        <option value="">-</option>
                                                        <option value="Garage">Garage</option>
                                                        <option value="Stapelparker - Garage">Stapelparker - Garage
                                                        </option>
                                                        <option value="Freifläche">Freifläche</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="toleranceMinutes">Toleranzminuten*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="text" id="tolerance_minutes" name="tolerance_minutes"
                                                           value={this.state.tolerance_minutes}
                                                           onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="freeMinutes">Freiminuten*</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="text" id="free_minutes" name="free_minutes"
                                                           value={this.state.free_minutes}
                                                           onChange={this.handleChange}
                                                           required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="max_weight">Gewicht-Limit</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <InputGroup>
                                                        <Input type="number" id="max_weight" name="max_weight"
                                                            value={this.state.max_weight}
                                                            onChange={this.handleChange}/>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>kg</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="handicapped_spots">Behindertenparkplätze</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="handicapped_spots" name="handicapped_spots"
                                                        value={this.state.handicapped_spots}
                                                        onChange={this.handleChange}>
                                                        <option value="">-</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md="4">
                                                    <Label htmlFor="security_guard">Objektsicherung</Label>
                                                </Col>
                                                <Col xs="12" md="8">
                                                    <Input type="select" id="security_guard" name="security_guard"
                                                        value={this.state.security_guard}
                                                        onChange={this.handleChange}>
                                                        <option value="">-</option>
                                                        <option value={true}>Ja</option>
                                                        <option value={false}>Nein</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4">
                        <Card>
                            <CardHeader>
                                <strong>Kontakt</strong>
                            </CardHeader>
                            <CardBody>
                                <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="6">
                                            <Label htmlFor="contactPerson">Anschpreperson*</Label>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <AsyncSelect
                                                value={!this.state.person_responsible ? null : this.state.person_responsible}
                                                onChange={this.savePR}
                                                loadOptions={this.loadPROptions}
                                                multi={true}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="6">
                                            <Label htmlFor="property_management_company">Hausverwaltung*</Label>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <AsyncSelect
                                                value={!this.state.property_management_company ? null : this.state.property_management_company}
                                                onChange={this.savePMCompany}
                                                loadOptions={this.loadPMOptions}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="6">
                                            <Label htmlFor="door_maintenance_company">Tür Wartungsfirma*</Label>
                                        </Col>
                                        <Col xs="12" md="6">
                                            <AsyncSelect
                                                value={!this.state.door_maintenance_company ? null : this.state.door_maintenance_company}
                                                loadOptions={this.loadDMOptions}
                                                onChange={this.saveDMCompany}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AddGarage;
