import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Button,
    Card,
    CardBody,
    CardHeader,
    Table
} from 'reactstrap';

import 'spinkit/css/spinkit.css';

import moment from "moment";
import garageService from "../../../services/garageService";

import getTransactionsQuery from "../../../queries/getTransactions";
import datetimeFormatter from '../../../utils/datetimeFormatter';
import dictionary from '../../../utils/dictionary';


class GarageCurrentParkers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            garage_id: this.props.garage_id,
            currentParkers: []
        };

        this.renderRows = this.renderRows.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
    }

    componentDidMount() {

        garageService.getService(getTransactionsQuery({
            garage_id: this.state.garage_id,
            completed: false
        }))
            .then((transactions) => {

                transactions = transactions.data.getTransactions.transactions;

                this.setState({
                    transactions: transactions,
                    loading: false,
                })
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    renderRows() {
        return this.state.transactions.map((currentParker, i) => {
            let type = dictionary.NOT_AVAILABLE;
            if (currentParker.extra_info) {
                const { nfc_parking, admin_start_parking } = currentParker.extra_info;
                type = nfc_parking ? 'NFC' : admin_start_parking ? 'COCKPIT' : 'APP';
            }

            return <tr key={i}>
                <td><Link to={"/users/"+currentParker.user}>{currentParker.user_email}</Link></td>
                <td>{datetimeFormatter.forDisplay(currentParker.start_time)}</td>
                <td>{
                    moment.duration((currentParker.end_time ? new Date(currentParker.end_time) : new Date()) - new Date(currentParker.start_time)).locale('de').humanize()
                }</td>
                <td>{currentParker.user_licence_plate}</td>
                <td>{type}</td>
                <td>{currentParker.total_price}</td>
            </tr>
        })
    }

    renderLoading() {
        return <tr>
            <td colSpan={6}><div className="sk-rotating-plane"/></td>
        </tr>
    }

    render() {

        return(
            <Card>
                <CardHeader>
                    Parkende Kunden
                </CardHeader>
                <CardBody>
                    <Table responsive striped size="sm">
                        <thead>
                        <tr>
                            <th>User</th>
                            <th>Letzter Parkbeginn</th>
                            <th>Dauer</th>
                            <th>Kennzeichen</th>
                            <th>Typ</th>
                            <th>Preis</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !this.state.loading ? this.renderRows() : this.renderLoading()
                        }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )

    }
}

export default GarageCurrentParkers;
