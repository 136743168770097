import React, {Component, useState} from 'react';
import {
    Button,
    Form,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import _isEqual from "lodash/isEqual";
import Select from "react-select";
import dictionary from "../../../utils/dictionary";
import warning from '../../../assets/img/warning.png';


export default class GarageTechnicalData extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disabledTechnicalData: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateGarage = this.updateGarage.bind(this);
        this.toggleTechnicalData = this.toggleTechnicalData.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        return {
            garage: state.garage || props.garage
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_isEqual(prevProps.garage, this.props.garage)) {
            // props have been changed
            this.setState({
                garage: this.props.garage
            })
        }
    }

    handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        let parent = target.getAttribute('parent');

        if (name === "gateway_type" && !value.length) {
            value = null;
        }

        let newState = {
            garage: {
                ...this.state.garage,
                [name]: value
            }
        };

        if(parent !== null) {
            newState = {
                garage: {
                    ...this.state.garage,
                    [parent]: {
                        ...this.state.garage[parent],
                        [name]: value
                    }
                }
            }
        }

        this.setState(newState);
    }

    updateGarage() {
        this.props.updateGarage(this.state.garage)
            .then(() => {
                    this.setState({
                        disabledTechnicalData: true
                    })
            })
            .catch(() => {
                this.setState({
                    disabledTechnicalData: false
                })
            })
    }

    toggleTechnicalData() {
        this.props.toggleTechnicalData()
    }

    sanitizeData(val) {
        return (val && val.length) ? val : ""
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Technische Daten
                    <div className="card-header-actions">
                        {
                            !this.props.garageTechnicalData
                                ?
                                <div>
                                    <Button type="submit" size="sm" color="success" className={"mr-1"} onClick={this.updateGarage}>
                                        <i className="fa fa-dot-circle-o" /> Speichern</Button>
                                    <Button type="reset" size="sm" color="danger" onClick={this.toggleTechnicalData}>
                                        <i className="fa fa-ban" /> Abbrechen
                                    </Button>
                                </div>
                                :
                                <a className="card-header-action btn btn-setting" onClick={this.toggleTechnicalData}><i className="icon-pencil"></i></a>
                        }
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        this.props.loading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Form action="" method="post" encType="multipart/form-data"
                                  className="form-horizontal">
                                <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="latitude">Breitengrad*</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="number" name="latitude" id="latitude" parent="position"
                                                       value={!this.state.garage.position.latitude ? "" : this.state.garage.position.latitude}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="longitude">Längengrad*</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="number" id="longitude" name="longitude" parent="position"
                                                       value={!this.state.garage.position.longitude ? "" : this.state.garage.position.longitude}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="echarger">e-Ladegerät</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="echarger" name="echarger"
                                                       value={this.state.garage.echarger}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="narrow"><img width={24} src={warning}/> Schmal</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="narrow" name="narrow"
                                                       value={this.state.garage.narrow}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={null}>Bitte auswahlen</option>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="accessible">Barrierefrei</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="barrierfree" name="barrierfree"
                                                       value={this.state.garage.barrierfree}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="cctv">CCTV</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="video_surveilance"
                                                       name="video_surveilance"
                                                       value={this.state.garage.video_surveilance}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="spaceWidth">Stellplatzbreite*</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <InputGroup>
                                                    <Input type="number" id="footprint" name="footprint"
                                                           value={!this.state.garage.footprint ? "" : this.state.garage.footprint}
                                                           disabled={this.props.garageTechnicalData}
                                                           onChange={this.handleChange} required/>
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>m</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="exitType">Ausgangstyp</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="exit_type"
                                                       name="exit_type"
                                                       value={this.sanitizeData(this.state.garage.exit_type)}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required>
                                                    <option value={"automatisch | automatically"}>automatisch | automatically</option>
                                                    <option value={"per Seilzug | by pulling the rope"}>per Seilzug | by pulling the rope</option>
                                                    <option value={"via App oder NFC | via App or NFC"}>via App oder NFC | via App or NFC</option>
                                                    <option value={"Türöffner | Door Opener"}>Türöffner | Door Opener</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="selectSm">Gateway Typ</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" name="gateway_type" id="gateway_type"
                                                       value={this.sanitizeData(this.state.garage.gateway_type)}
                                                       onChange={this.handleChange}
                                                       disabled={
                                                           !!this.props.garageTechnicalData ||
                                                           (
                                                               (this.state.garage.payuca_connect_gateways.length > 0) ||
                                                               (this.state.garage.garage_gateways.length > 0) ||
                                                               (this.state.garage.garageGateways.length > 0)
                                                           )
                                                       }>
                                                    <option value={""}>-</option>
                                                    <option value={"1.0"}>1.0</option>
                                                    <option value={"2.0"}>2.0</option>
                                                    <option value={"PC"}>Payuca Connect</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="selectSm">Produkttyp Smart Revenue</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" name="smart_revenue" id="smart_revenue"
                                                       value={this.state.garage.smart_revenue}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={null}>-</option>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="selectSm">Produkttyp Smart Access</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" name="smart_access" id="smart_access"
                                                       value={this.state.garage.smart_access}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={null}>-</option>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="garageHeight">Garagenhöhe*</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <InputGroup>
                                                    <Input type="number" id="garage_height" name="garage_height"
                                                           value={!this.state.garage.garage_height ? "" : this.state.garage.garage_height}
                                                           disabled={this.props.garageTechnicalData}
                                                           onChange={this.handleChange} required/>
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>m</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="indoor">Innen</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="indoor" name="indoor"
                                                       value={this.state.garage.indoor}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="floors">Stockwerke</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="text" id="levels" name="levels"
                                                       value={!this.state.garage.levels ? "" : this.state.garage.levels}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="garageType">Garagentyp*</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="type" name="type"
                                                       value={this.sanitizeData(this.state.garage.type)}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required>
                                                    <option value="">-</option>
                                                    <option value="Garage">Garage</option>
                                                    <option value="Stapelparker - Garage">Stapelparker - Garage</option>
                                                    <option value="Freifläche">Freifläche</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="toleranceMinutes">Toleranzminuten**</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="text" id="tolerance_minutes"
                                                       name="tolerance_minutes"
                                                       value={this.state.garage.tolerance_minutes}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="freeMinutes">Freiminuten**</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="text" id="free_minutes" name="free_minutes"
                                                       value={!this.state.garage.free_minutes ? "" : this.state.garage.free_minutes}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange} required/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="max_weight">Gewicht-Limit</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <InputGroup>
                                                    <Input type="number" id="max_weight" name="max_weight"
                                                        value={this.state.garage.max_weight}
                                                        disabled={this.props.garageTechnicalData}
                                                        onChange={this.handleChange}/>
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>kg</InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="handicapped_spots">Behindertenparkplätze</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="handicapped_spots" name="handicapped_spots"
                                                       value={this.state.garage.handicapped_spots}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value="">-</option>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col md="4">
                                                <Label htmlFor="security_guard">Objektsicherung</Label>
                                            </Col>
                                            <Col xs="12" md="8">
                                                <Input type="select" id="security_guard" name="security_guard"
                                                       value={this.state.garage.security_guard}
                                                       disabled={this.props.garageTechnicalData}
                                                       onChange={this.handleChange}>
                                                    <option value="">-</option>
                                                    <option value={true}>Ja</option>
                                                    <option value={false}>Nein</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <small>
                                            *<strong>Pflichtfeld</strong>.
                                        </small> <br />
                                        <small>
                                            **<strong>Toleranzminuten</strong>: Beschreibt nach wie vielen minuten kann sich ein Kunde aus der Garage, nach dem Check-In auschecken.
                                        </small> <br />
                                        <small>
                                            **<strong>Freiminuten</strong>: Beschreibt nach wie vielen Minuten ein neuer Takt beginnt.
                                        </small>
                                    </Col>
                                </Row>
                            </Form>
                    }
                </CardBody>
            </Card>
        )
    }

}
